import React from 'react';

import { Dialog } from './Dialog';

export const DeleteTagDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="remove-tag"
          title="Remove tag?"
          subtitle="Removing this tag will also remove it from all the docs."
          actionTitle="Remove"
          cancelTitle="Cancel"
          redActionButton
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }
  return <></>;
};
