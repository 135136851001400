import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { openEditTagSubMenu } from '../../../shared/foreground/cmdPalette';
import { globalState } from '../../../shared/foreground/models';
import {
  useGlobalTagsAsObject,
  useSavedFilteredViews,
  useViewsByTagId,
} from '../../../shared/foreground/stateHooks';
import {
  setSortTagsByKey,
  setSortTagsByOrder,
} from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/sortManagement';
import {
  removeTagFromAllDocs,
  removeTagsFromAllDocs,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import {
  saveFilteredView,
  updateFilteredView,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import {
  setFocusedDocumentId,
  setFocusedTagId,
} from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import useStatePlusLiveValueRef from '../../../shared/foreground/utils/useStatePlusLiveValueRef';
import { FilteredView, SortOrder, TableHeader, TableSortKey } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import type { GlobalTag, GlobalTagsObject } from '../../../shared/types/tags';
import getFormattedDurationFromNow from '../../../shared/utils/dates/getFormattedDurationFromNow';
import useDebounce from '../../../shared/utils/useDebounce';
import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import useOnItemChecked from '../hooks/useOnItemChecked';
import useScrollIntoViewIfNeeded from '../hooks/useScrollIntoViewIfNeeded';
import { useShortcutsMap } from '../utils/shortcuts';
import { draggableTagListItemPrefix, tagsListDroppableId } from '../utils/sidebar';
import BulkActionsHeader from './BulkActionsHeader';
import Button from './Button';
import { CustomCheckbox } from './Checkbox';
import { DeleteTagDialog } from './DeleteTagDialog';
import { DeleteTagsDialog } from './DeleteTagsDialog';
import BulkTagsViewsDropdown from './Dropdown/BulkTagsViewsDropdown';
import TagViewsDropdown from './Dropdown/TagViewsDropdown';
import { FloatingPill } from './FloatingPill';
import MergeIcon from './icons/16StrokeMerge';
import TrashIcon from './icons/16StrokeTrash';
import StrokePinIcon from './icons/StrokePinIcon';
import StrokePinnedIcon from './icons/StrokePinnedIcon';
import LastUpdatedOrActionButtons, { DeleteButton, EditButton } from './LastUpdatedOrActionButtons';
import { MergeTagsDialog } from './MergeTagsDialog';
import SearchInput from './SearchInput';
import { ShowNavigationLeftPanelButton } from './ShowNavigationLeftPanelButton';
import { Table } from './Table';
import styles from './TagsList.module.css';
import Tooltip from './Tooltip';

const TagsBulkActionsHeader = React.memo(function TagsBulkActionsHeader({
  onCheckedChange,
  views,
  viewsByTagId,
  isChecked,
  setSelectedIds,
  selectedIds,
  areAllItemsSelected = false,
  globalTagsObject,
}: {
  onCheckedChange: () => void;
  isChecked: boolean;
  setSelectedIds: (v: string[]) => void;
  selectedIds: string[];
  areAllItemsSelected: boolean;
  views: FilteredView[];
  viewsByTagId: { [tagId: string]: FilteredView[] };
  globalTagsObject: GlobalTagsObject;
}) {
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isViewAssociatedWithGlobalTags = useCallback(
    (viewId: string) => {
      let isAssociatedWithGlobalTags = true;

      for (const tagId of selectedIds) {
        const views = viewsByTagId[tagId];
        const isAssociatedWithTag = views && views.some((view) => view.id === viewId);
        if (!isAssociatedWithTag) {
          isAssociatedWithGlobalTags = false;
          break;
        }
      }

      return isAssociatedWithGlobalTags;
    },
    [selectedIds, viewsByTagId],
  );

  const associatedViews: (FilteredView & { isAssociatedWithGlobalTags: boolean })[] = useMemo(() => {
    const allViews: (FilteredView & { isAssociatedWithGlobalTags?: boolean })[] = [];

    selectedIds.forEach((tagId) => {
      if (viewsByTagId[tagId]) {
        allViews.push(...viewsByTagId[tagId]);
      }
    });

    return uniqBy(allViews, 'id').map((view) => {
      const isAssociatedWithGlobalTags = isViewAssociatedWithGlobalTags(view.id);

      return {
        ...view,
        isAssociatedWithGlobalTags,
      } as FilteredView & { isAssociatedWithGlobalTags: boolean };
    });
  }, [selectedIds, viewsByTagId, isViewAssociatedWithGlobalTags]);

  const deleteSelectedTags = useCallback(() => {
    const tagNames = [];

    for (const tagId of selectedIds) {
      const tag = globalTagsObject[tagId];
      tagNames.push(tag.name);
    }

    removeTagsFromAllDocs({ tagNames });

    setSelectedIds([]);
  }, [globalTagsObject, selectedIds, setSelectedIds]);

  const isMergeEnabled = selectedIds.length >= 2;

  return (
    <>
      <BulkActionsHeader
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        resourceName="Tag"
        onCheckedChange={onCheckedChange}
        isChecked={isChecked}
        isMinusIcon={!areAllItemsSelected}
      >
        <BulkTagsViewsDropdown
          associatedViews={associatedViews}
          globalTagsObject={globalTagsObject}
          selectedIds={selectedIds}
          views={views}
        />

        <Button
          className={`${styles.mergeButton} ${isMergeEnabled ? '' : styles.buttonDisabled}`}
          variant="secondary"
          onClick={() => setIsMergeDialogOpen(true)}
        >
          <MergeIcon /> Merge
        </Button>

        <Button
          className={styles.deleteButton}
          variant="secondary"
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          <TrashIcon /> Delete
        </Button>
      </BulkActionsHeader>

      <MergeTagsDialog
        globalTagsObject={globalTagsObject}
        isOpen={isMergeDialogOpen}
        selectedTagIds={selectedIds}
        setIsOpen={setIsMergeDialogOpen}
        setSelectedTagIds={setSelectedIds}
      />

      <DeleteTagsDialog
        isOpen={isDeleteDialogOpen}
        onConfirm={deleteSelectedTags}
        count={selectedIds.length}
        onCancel={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
});

const TagLastUpdatedOrActionButtons = React.memo(function _TagLastUpdatedOrActionButtons({
  id,
  tagName,
  lastAssigned,
  isFocused,
  deleteShortcut,
  onDelete,
  areSelectedItems,
  viewThatMatchExactlyTheTagQuery,
}: {
  id: string;
  tagName: string;
  lastAssigned?: number;
  isFocused: boolean;
  deleteShortcut: string | string[];
  onDelete: (id: string) => void;
  areSelectedItems: boolean;
  viewThatMatchExactlyTheTagQuery?: FilteredView;
}) {
  const lastAssignedFromNow = useMemo(
    () => (lastAssigned ? getFormattedDurationFromNow(lastAssigned) : '-'),
    [lastAssigned],
  );

  const handleOnDelete = useCallback(() => onDelete(id), [id, onDelete]);

  return (
    <LastUpdatedOrActionButtons
      lastUpdated={lastAssignedFromNow}
      isFocused={isFocused}
      areSelectedItems={areSelectedItems}
    >
      <EditButton onClick={openEditTagSubMenu} />

      <Tooltip content={viewThatMatchExactlyTheTagQuery ? 'Unpin from sidebar' : 'Pin to sidebar'}>
        <Button
          tabIndex={-1}
          onClick={() => {
            if (viewThatMatchExactlyTheTagQuery) {
              updateFilteredView(
                {
                  ...viewThatMatchExactlyTheTagQuery,
                  isUnpinned: !viewThatMatchExactlyTheTagQuery.isUnpinned,
                },
                { userInteraction: 'keypress' },
              );
            } else {
              const filter = {
                name: tagName,
                query: `tag:"${tagName}"`,
                order: Infinity,
              };
              saveFilteredView(filter, window.location.pathname, { userInteraction: 'keypress' });
            }
          }}
        >
          {viewThatMatchExactlyTheTagQuery ? <StrokePinnedIcon /> : <StrokePinIcon />}
        </Button>
      </Tooltip>

      <DeleteButton shortcut={deleteShortcut} onClick={handleOnDelete} />
    </LastUpdatedOrActionButtons>
  );
});

interface TagItemProps {
  areSelectedItems: boolean;
  associatedViews?: FilteredView[];
  deleteShortcut: string | string[];
  id: string;
  index: number;
  isChecked: boolean;
  isCmdPaletteOpen: boolean;
  isFocused: boolean;
  onCheckedChangeWithShiftInfo: ({
    isChecked,
    isShiftKey,
    id,
    index,
  }: { isChecked: boolean; isShiftKey: boolean; id: string; index: number }) => void;
  onDelete: (id: string) => void;
  setSelectedId: (id: string) => void;
  tag: GlobalTag;
  views?: FilteredView[];
  viewThatMatchExactlyTheTagQuery?: FilteredView;
}

const TagItem = React.memo(function _TagItem({
  id,
  tag,
  onCheckedChangeWithShiftInfo,
  index,
  isChecked,
  areSelectedItems,
  views,
  associatedViews,
  isFocused,
  isCmdPaletteOpen,
  setSelectedId,
  onDelete,
  deleteShortcut,
  viewThatMatchExactlyTheTagQuery,
}: TagItemProps) {
  const itemRef = useRef<HTMLTableRowElement>(null);
  const name = tag.name;
  const firstClassDocumentsCount = tag.firstClassDocumentsCount;
  const highlightsCount = tag.highlightsCount;
  const lastAssignedAt = tag.lastAssignedAt;

  const setSelectedIdIfDropdownNotOpen = useCallback(() => {
    if (window.isRadixDropdownOpen || isCmdPaletteOpen) {
      return;
    }

    setSelectedId(id);
  }, [id, isCmdPaletteOpen, setSelectedId]);

  const headerHeight = 109;
  useScrollIntoViewIfNeeded(itemRef, isFocused, headerHeight);

  const linkTo = `/filter/tag:"${encodeURIComponent(name)}"`;

  const onRowClick = useCallback(
    (e: React.MouseEvent) => {
      if (!onCheckedChangeWithShiftInfo || !areSelectedItems) {
        return;
      }

      onCheckedChangeWithShiftInfo({ isChecked: !isChecked, isShiftKey: e.shiftKey, id, index });
    },
    [onCheckedChangeWithShiftInfo, id, index, isChecked, areSelectedItems],
  );

  const onCheckedChange = useCallback(() => {
    onCheckedChangeWithShiftInfo({ isChecked: !isChecked, isShiftKey: false, id, index });
  }, [onCheckedChangeWithShiftInfo, id, index, isChecked]);

  return (
    <tr
      ref={itemRef}
      className={`${styles.item} ${isFocused ? styles.isFocused : ''} ${
        isChecked ? styles.isChecked : ''
      }`}
      onMouseOver={setSelectedIdIfDropdownNotOpen}
      onFocus={setSelectedIdIfDropdownNotOpen}
      onClick={onRowClick}
    >
      <td className={styles.checkboxWrapper}>
        <CustomCheckbox label={`tag-${id}`} isChecked={isChecked} onCheckedChange={onCheckedChange} />
      </td>
      <Draggable
        draggableId={`${draggableTagListItemPrefix}${name}`}
        index={index}
        isDragDisabled={false}
      >
        {(provided, snapshot) => {
          return (
            <td className={`${snapshot.isDragging ? styles.isDragging : ''}`}>
              <Link
                to={linkTo}
                className={styles.name}
                style={provided.draggableProps.style}
                {...(provided?.draggableProps ?? {})}
                {...(provided?.dragHandleProps ?? {})}
                ref={provided.innerRef}
              >
                <span>{name}</span>
              </Link>
            </td>
          );
        }}
      </Draggable>
      <td className={styles.documents}>{firstClassDocumentsCount}</td>
      <td className={styles.documents}>{highlightsCount}</td>
      <td className={styles.views}>
        <TagViewsDropdown
          tagId={id}
          tag={tag}
          views={views}
          associatedViews={associatedViews}
          isFocused={isFocused}
        />
      </td>
      <td className={styles.lastUpdated}>
        <TagLastUpdatedOrActionButtons
          id={id}
          lastAssigned={lastAssignedAt}
          isFocused={isFocused}
          deleteShortcut={deleteShortcut}
          onDelete={onDelete}
          areSelectedItems={areSelectedItems}
          viewThatMatchExactlyTheTagQuery={viewThatMatchExactlyTheTagQuery}
          tagName={name}
        />
      </td>
    </tr>
  );
});

export const TagsList = React.memo(function TagsList() {
  const [selectedIds, setSelectedIds, selectedIdsRef] = useStatePlusLiveValueRef<string[]>([]);
  const shortcutsMap = useShortcutsMap();
  const [deleteTagDialogOpen, setDeleteTagDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const focusedTagId = globalState(useCallback((state) => state.focusedTagId, []));
  const sortByKey = globalState(useCallback((state) => state.client.sortTagsByKey, []));
  const sortOrder = globalState(useCallback((state) => state.client.sortTagsByOrder, []));
  const [globalTagsObject] = useGlobalTagsAsObject();
  const isCmdPaletteOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));
  const tagIds = useMemo(() => Object.keys(globalTagsObject), [globalTagsObject]);
  const views = useSavedFilteredViews();
  const viewsThatIncludeTagInQueryByTagId = useViewsByTagId();
  const viewThatMatchExactlyTheTagQueryByTagId = useMemo(() => {
    const initialAcc: {
      [tagId: string]: FilteredView | undefined;
    } = {};
    return Object.keys(viewsThatIncludeTagInQueryByTagId).reduce((acc, tagId) => {
      const viewsThatIncludeTagInQuery = viewsThatIncludeTagInQueryByTagId[tagId];
      const globalTag = globalTagsObject[tagId];
      if (globalTag) {
        const view = viewsThatIncludeTagInQuery.find(
          (view) => !view.isUnpinned && view.query === `tag:"${globalTag.name}"`,
        );
        if (view) {
          acc[tagId] = view;
        }
      }
      return acc;
    }, initialAcc);
  }, [globalTagsObject, viewsThatIncludeTagInQueryByTagId]);

  // Doing this to prevent changing focus after creating a new view
  const debouncedIsCmdPaletteOpen = useDebounce(isCmdPaletteOpen, 500);
  const debouncedIsCmdPaletteOpenRef = useRef(false);

  useEffect(() => {
    debouncedIsCmdPaletteOpenRef.current = debouncedIsCmdPaletteOpen;
  }, [debouncedIsCmdPaletteOpen]);

  const filterFn = useCallback(
    (tagId: string) => {
      const tag = globalTagsObject[tagId];
      const matchesName = tag.name && tag.name.toLowerCase().includes(searchQuery.toLowerCase());
      const tagViews = viewsThatIncludeTagInQueryByTagId[tagId];
      const matchesViews =
        tagViews && tagViews.some((view) => view.name.toLowerCase().includes(searchQuery.toLowerCase()));
      return matchesName || matchesViews;
    },
    [searchQuery, globalTagsObject, viewsThatIncludeTagInQueryByTagId],
  );

  const filteredTagsKeys = useMemo(
    () => (searchQuery ? tagIds.filter(filterFn) : tagIds),
    [tagIds, filterFn, searchQuery],
  );

  const orderedTagsKeys = useMemo(() => {
    return orderBy(
      filteredTagsKeys,
      [
        (id) => {
          const tag = globalTagsObject[id];
          switch (sortByKey) {
            case TableSortKey.Name:
              return tag.name?.toLocaleLowerCase() || '';
            case TableSortKey.Documents:
              return tag.firstClassDocumentsCount;
            case TableSortKey.HighlightsCount:
              return tag.highlightsCount;
            case TableSortKey.Views:
              return viewsThatIncludeTagInQueryByTagId[id]?.length ?? 0;
            case TableSortKey.LastUpdated: {
              const lastAssigned = tag.lastAssignedAt;

              if (!lastAssigned && sortOrder === SortOrder.Asc) {
                return Infinity;
              } else {
                return lastAssigned ?? 0;
              }
            }
          }
        },
      ],
      [sortOrder],
    );
  }, [filteredTagsKeys, globalTagsObject, viewsThatIncludeTagInQueryByTagId, sortByKey, sortOrder]);

  const onHeaderCheckedChange = useCallback(() => {
    setSelectedIds((prev) => (prev.length ? [] : orderedTagsKeys));
  }, [setSelectedIds, orderedTagsKeys]);

  const onHeaderClick = useCallback(
    (key: TableSortKey) => {
      if (key === sortByKey) {
        setSortTagsByOrder(sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
        return;
      }

      setSortTagsByKey(key);
    },
    [sortByKey, sortOrder],
  );

  const onCheckedChangeWithShiftInfo = useOnItemChecked({
    selectedIds: selectedIdsRef.current,
    setSelectedIds,
    allIds: orderedTagsKeys,
  });

  useEffect(() => {
    return () => {
      setFocusedTagId(null);
    };
  }, []);

  useEffect(() => {
    if (
      orderedTagsKeys.length > 0 &&
      !window.isRadixDropdownOpen &&
      !debouncedIsCmdPaletteOpenRef.current
    ) {
      setFocusedTagId(orderedTagsKeys[0]);
    }

    setFocusedDocumentId(null, { userInteraction: 'unknown' });
  }, [orderedTagsKeys]);

  const navItems = useCallback(
    (direction: number) => {
      if (isCmdPaletteOpen) {
        return;
      }

      const currentIndex = orderedTagsKeys.findIndex((key) => {
        return key === focusedTagId;
      });

      if (direction > 0) {
        if (orderedTagsKeys[currentIndex + 1]) {
          setFocusedTagId(orderedTagsKeys[currentIndex + 1]);
        }
        return;
      }

      if (orderedTagsKeys[currentIndex - 1]) {
        setFocusedTagId(orderedTagsKeys[currentIndex - 1]);
      }
    },
    [orderedTagsKeys, focusedTagId, isCmdPaletteOpen],
  );

  const onDelete = useCallback((id: string) => {
    setFocusedTagId(id);
    setDeleteTagDialogOpen(true);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (!focusedTagId) {
      return;
    }

    const tag = globalTagsObject[focusedTagId];
    removeTagFromAllDocs({ tagName: tag.name, options: { userInteraction: 'unknown' } });
    navItems(1);
    setDeleteTagDialogOpen(false);
  }, [focusedTagId, navItems, globalTagsObject]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Down],
    useCallback(() => navItems(1), [navItems]),
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Up],
    useCallback(() => navItems(-1), [navItems]),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.DeleteDocument],
    useCallback(() => {
      if (focusedTagId) {
        setDeleteTagDialogOpen(true);
      }
    }, [focusedTagId]),
  );

  const tableHeaders = useMemo(
    () =>
      [
        {
          title: 'Name',
          sortkey: TableSortKey.Name,
        },
        {
          title: 'Documents',
          sortkey: TableSortKey.Documents,
        },
        {
          title: 'Highlights',
          sortkey: TableSortKey.HighlightsCount,
        },
        {
          title: 'Views',
          sortkey: TableSortKey.Views,
        },
        {
          title: 'Last Used',
          sortkey: TableSortKey.LastUpdated,
        },
      ] as TableHeader[],
    [],
  );

  const areSelectedItems = useMemo(() => selectedIds.length > 0, [selectedIds]);

  return (
    <div className={`${styles.sources} ${areSelectedItems ? styles.areSelectedItems : ''}`}>
      <div className={styles.sourcesContainer}>
        <div className={styles.header}>
          <span className={styles.title}>
            <ShowNavigationLeftPanelButton />
            Tags
          </span>
          <div className={styles.headerRight}>
            <SearchInput setQuery={setSearchQuery} />
          </div>
        </div>
        <div className={`${styles.listContainer} has-visible-scrollbar`}>
          {areSelectedItems && (
            <TagsBulkActionsHeader
              areAllItemsSelected={selectedIds.length === orderedTagsKeys.length}
              globalTagsObject={globalTagsObject}
              isChecked
              onCheckedChange={onHeaderCheckedChange}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              views={views}
              viewsByTagId={viewsThatIncludeTagInQueryByTagId}
            />
          )}

          <table>
            {!areSelectedItems && (
              <Table.Header
                onCheckedChange={onHeaderCheckedChange}
                onHeaderClick={onHeaderClick}
                headers={tableHeaders}
                currentSortKey={sortByKey}
                currentSortOder={sortOrder}
                coverBorder={false}
              />
            )}

            <Droppable droppableId={tagsListDroppableId} type="pinned-views">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedTagsKeys.map((id, index) => {
                    const associatedViews = viewsThatIncludeTagInQueryByTagId[id];

                    return (
                      <TagItem
                        areSelectedItems={areSelectedItems}
                        associatedViews={associatedViews}
                        deleteShortcut={shortcutsMap[ShortcutId.DeleteDocument]}
                        id={id}
                        index={index}
                        isChecked={selectedIds.includes(id)}
                        isCmdPaletteOpen={isCmdPaletteOpen}
                        isFocused={focusedTagId === id}
                        key={id}
                        onCheckedChangeWithShiftInfo={onCheckedChangeWithShiftInfo}
                        onDelete={onDelete}
                        setSelectedId={setFocusedTagId}
                        tag={globalTagsObject[id]}
                        views={views}
                        viewThatMatchExactlyTheTagQuery={viewThatMatchExactlyTheTagQueryByTagId[id]}
                      />
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>

        {Boolean(filteredTagsKeys.length) && (
          <FloatingPill>
            <>Count: {filteredTagsKeys.length.toLocaleString()}</>
          </FloatingPill>
        )}

        <DeleteTagDialog
          isOpen={deleteTagDialogOpen}
          onConfirm={onDeleteConfirm}
          onCancel={() => setDeleteTagDialogOpen(false)}
        />
      </div>
    </div>
  );
});
